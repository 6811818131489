import { Base } from 'domains/Base';
import { MILEAGE_UNIT } from 'types';
import type {
  UserRating,
  UserVerification,
} from 'components/PageWrappers/AdDetails/types/index';

export type VehicleRenewal = {
  displayText: string;
  value: string;
};

export enum STRIPE_STATUS {
  CONNECTED = 'CONNECTED',
  NOT_CONNECTED = 'NOT_CONNECTED',
  NOT_AVAILABLE = 'NOT_AVAILABLE',
}

type Trader = {
  name: string;
  address?: string;
  vat?: string;
} | null;

type Stripe = {
  status: STRIPE_STATUS;
  clientId: string | null;
  accountId: string | null;
  state: string | null;
};

type CommunicationSettingsOptions = {
  email: boolean;
  notification: boolean;
  none: boolean;
};

type Consent = {
  acceptedTermsOfUse: boolean;
  productRecommendationsOption: boolean;
  communicationSettingsOptions: CommunicationSettingsOptions;
};

export type Vehicle = {
  registrationNumber: string | null;
  isNotVehicleOwner: boolean;
  mileage: string | null;
  mileageUnit: MILEAGE_UNIT | null;
  insuranceRenewal?: string | null;
  insuranceTargetable?: boolean;
};

export type NotificationPreferencesCategories = {
  newsAndOffers: boolean;
  savedSearch: boolean;
  sellingTips: boolean;
  recommendations: boolean;
  savedAds: boolean;
  interactionFeedback: boolean;
};

type AdView = {
  displayText?: string;
  value?: string;
};

export type AdCountStats = {
  activeAdView?: AdView;
  lifetimeAdView?: AdView;
};

export interface User extends Base {
  name: string;
  phone: string;
  county: string;
  town: string;
  isPhoneVerified: boolean;
  isEmailVerified: boolean;
  trader?: Trader;
  email?: string;
  stripe?: Stripe;
  vehicle?: Vehicle | null;
  consent?: Consent;

  rating: UserRating | null;
  verification?: UserVerification;
  type: 'PRIVATE' | 'TRADER' | 'DEALER' | null;
  membershipDuration: string | null;
  registrationDate?: string | null;
  avgResponseRate?: string | null;
  adCountStats?: AdCountStats | null;
}

export const createUser = ({
  id,
  name,
  email,
  phone,
  county,
  town,
  isPhoneVerified,
  isEmailVerified,
  rating,
  verification,
  type,
  membershipDuration,
  registrationDate,
  avgResponseRate,
  adCountStats,
  trader,
  stripe,
  consent,
  vehicle,
}: User): User => {
  return {
    id,
    name,
    email,
    phone,
    county,
    town,
    isPhoneVerified,
    isEmailVerified,
    rating,
    verification,
    type,
    membershipDuration,
    registrationDate,
    avgResponseRate,
    adCountStats,
    trader,
    stripe,
    consent,
    vehicle,
  };
};

export const isUserTrader = (user: User) => Boolean(user.trader);
export const isStripeConnected = (stripeStatus: STRIPE_STATUS) =>
  stripeStatus === STRIPE_STATUS.CONNECTED;
export const isStripeAvailable = (stripeStatus: STRIPE_STATUS) =>
  stripeStatus !== STRIPE_STATUS.NOT_AVAILABLE;
export const isMinimumVerified = (user: User) =>
  Boolean(user.isEmailVerified || user.isPhoneVerified);
