import { error, ok, Result } from 'domains/Result';
import {
  MyVehicleUpdateRequest,
  TGetUser,
  TUpdateMyVehicle,
  TUpdateUser,
} from 'api/types/accountsApiTypes';
import {
  createUser,
  isUserTrader,
  NotificationPreferencesCategories,
  STRIPE_STATUS,
  User,
  Vehicle,
  VehicleRenewal,
} from 'domains/User';
import type { IncomingHttpHeaders } from 'http';
import StatusCode from 'status-code-enum';
import { createNotification, Notification } from 'domains/Notification';
import {
  accountsApi,
  TRemoveStripeAccount,
  TUpdatePhone,
} from 'api/accountsApi';
import {
  GetUserResponse,
  TGetPaymentOptions,
  TRemovePaymentOption,
  TUpdatePassword,
  IUpdatePassword,
  TGetNotificationPreferences,
  TUpdatetNotificationPreference,
  MyVehicle,
} from 'api/types/accountsApiTypes';
import { PaymentMethod, PAYMENT_METHOD_TYPE } from 'domains/Payment';
import { TPaymentCard } from 'types/orderTypes';
import type { Base, Message } from 'domains/Base';
import { mapPaymentIcon } from 'repositories/Order/OrderRepository';
import {
  isValidationError,
  ResponseError,
  ValidationError,
} from 'domains/Error';

export interface IUserDataSource {
  getUser: TGetUser;
  updateUser: TUpdateUser;
  updatePhone: TUpdatePhone;
  removeStripeAccount: TRemoveStripeAccount;
  getPaymentOptions: TGetPaymentOptions;
  removePaymentOption: TRemovePaymentOption;
  updatePassword: TUpdatePassword;
  getNotificationPreferences: TGetNotificationPreferences;
  updateNotificationPreference: TUpdatetNotificationPreference;
  updateMyVehicle: TUpdateMyVehicle;
}

interface IUserRepository {
  get: (headers: IncomingHttpHeaders) => Promise<
    Result<
      Error,
      {
        user: User;
        notification: Notification;
        vehicleRenewalList: VehicleRenewal[];
      }
    >
  >;
  update: (
    user: User,
    headers: IncomingHttpHeaders,
  ) => Promise<Result<ResponseError, {}>>;
  updatePhone: (
    phone: string,
    headers: IncomingHttpHeaders,
  ) => Promise<Result<Error, {}>>;
  removeStripeAccount: (
    headers: IncomingHttpHeaders,
  ) => Promise<Result<Error, {}>>;
  getPaymentOptions: (
    headers: IncomingHttpHeaders,
  ) => Promise<Result<Error, PaymentMethod[]>>;
  removePaymentOption: (
    method: PAYMENT_METHOD_TYPE,
    headers: IncomingHttpHeaders,
  ) => Promise<Result<Error, Base>>;
  updatePassword: (
    args: IUpdatePassword,
    headers: IncomingHttpHeaders,
  ) => Promise<Result<ResponseError, {}>>;
  getNotificationPreferences: (
    headers: IncomingHttpHeaders,
  ) => Promise<Result<Error, NotificationPreferencesCategories>>;
  updateNotificationPreference: (
    category: string,
    subscriptionStatus: boolean,
    headers: IncomingHttpHeaders,
  ) => Promise<Result<Error, Message>>;
  updateMyVehicle: (
    vehicleData: MyVehicleUpdateRequest,
    headers: IncomingHttpHeaders,
  ) => Promise<Result<Error, {}>>;
}

const mapStripeStatus = (stripeInfo?: {
  state?: string;
  clientId?: string;
  accountId?: string;
}) => {
  if (!Boolean(stripeInfo)) return STRIPE_STATUS.NOT_AVAILABLE;
  else if (Boolean(stripeInfo?.accountId)) return STRIPE_STATUS.CONNECTED;
  else return STRIPE_STATUS.NOT_CONNECTED;
};

const mapUserVehicle: (data: MyVehicle) => Vehicle = (data) => {
  return {
    registrationNumber: data.registrationNumber,
    mileage: data.mileage ? data.mileage.toString() : null,
    mileageUnit: data.mileageUnit,
    isNotVehicleOwner: false,
    insuranceRenewal: data.insuranceRenewal,
    insuranceTargetable: data.insuranceTargetable ?? false,
  };
};

const mapUser = (userResponse: GetUserResponse) =>
  createUser({
    id: userResponse.userId,
    name: userResponse.name,
    email: userResponse.email,
    phone: userResponse.telephone,
    county: userResponse.county,
    town: userResponse.town,
    isPhoneVerified: userResponse.verification.phone,
    isEmailVerified: userResponse.verification.email,
    verification: userResponse.verification,
    rating: null,
    type: null,
    membershipDuration: null,
    registrationDate: null,
    avgResponseRate: null,
    adCountStats: null,
    trader: userResponse.trader
      ? {
          name: userResponse.tradername,
          address: userResponse.traderaddress,
          vat: userResponse.tradervatnumber,
        }
      : null,
    stripe: {
      status: mapStripeStatus(userResponse.stripeInfo),
      accountId: userResponse.stripeInfo?.accountId || null,
      clientId: userResponse.stripeInfo?.clientId || null,
      state: userResponse.stripeInfo?.state || null,
    },
    consent: {
      acceptedTermsOfUse: userResponse.consent.acceptedTermsOfUse,
      productRecommendationsOption: userResponse.consent.recommendationsOptIn,
      communicationSettingsOptions: {
        email: userResponse.consent.marketingPermission.email,
        notification: userResponse.consent.marketingPermission.notification,
        none: userResponse.consent.marketingPermission.none,
      },
    },
    vehicle: mapUserVehicle(userResponse.myVehicle),
  });

const UserRepository = (userDataSource: IUserDataSource): IUserRepository => {
  const get: IUserRepository['get'] = async (
    getHeaders: IncomingHttpHeaders,
  ) => {
    try {
      const { data, headers } = await userDataSource.getUser(getHeaders);
      return ok({
        user: mapUser(data),
        notification: createNotification(
          headers.totalunread,
          headers.unreadhistorychecks,
          headers.unreadmessages,
        ),
        vehicleRenewalList: data.myVehicle.insuranceRenewalList,
      });
    } catch (err: any) {
      return error(new Error(err));
    }
  };

  const update: IUserRepository['update'] = async (user: User, headers) => {
    try {
      await userDataSource.updateUser(
        {
          userId: user.id,
          name: user.name,
          email: user.email || '',
          telephone: user.phone,
          county: user.county,
          town: user.town,
          trader: isUserTrader(user),
          tradername: user.trader?.name || '',
          traderaddress: user.trader?.address || '',
          tradervatnumber: user.trader?.vat || '',
          verification: {
            email: user.isEmailVerified,
            phone: user.isPhoneVerified,
          },
          isVerifiableNumber: user.isPhoneVerified,
          consent: {
            acceptedTermsOfUse: user.consent?.acceptedTermsOfUse || false,
            recommendationsOptIn:
              user.consent?.productRecommendationsOption || false,
            marketingPermission: {
              email: user.consent?.communicationSettingsOptions.email || false,
              notification:
                user.consent?.communicationSettingsOptions.notification ||
                false,
              none: user.consent?.communicationSettingsOptions.none || false,
            },
          },
          myVehicle: {
            registrationNumber: user.vehicle?.registrationNumber || '',
            mileage: user.vehicle?.mileage
              ? parseInt(user.vehicle.mileage)
              : null,
            mileageUnit: user.vehicle?.mileageUnit || null,
            notOwnVehicle: user.vehicle?.isNotVehicleOwner ?? false,
            insuranceRenewal: user.vehicle?.insuranceRenewal || '',
            insuranceRenewalList: [],
          },
        },
        headers,
      );
      return ok({});
    } catch (err) {
      if (isValidationError(err.response?.status as number)) {
        return error(<ResponseError>{
          type: 'VALIDATION',
          error: err.response?.data as ValidationError,
        });
      } else {
        return error({
          type: 'UNKNOWN',
          error: new Error(err),
        });
      }
    }
  };

  const updatePhone: IUserRepository['updatePhone'] = async (
    phone,
    headers,
  ) => {
    try {
      await userDataSource.updatePhone(
        {
          phoneNumber: phone,
        },
        headers,
      );
      return ok({});
    } catch (err: any) {
      return error(new Error(err));
    }
  };

  const removeStripeAccount: IUserRepository['removeStripeAccount'] = async (
    headers,
  ) => {
    try {
      await userDataSource.removeStripeAccount(headers);
      return ok({});
    } catch (err: any) {
      return error(new Error(err));
    }
  };

  const updatePassword: IUserRepository['updatePassword'] = async (
    { confirmPassword, confirmPassword2, password }: IUpdatePassword,
    headers: IncomingHttpHeaders,
  ) => {
    try {
      await userDataSource.updatePassword(
        {
          confirmPassword,
          confirmPassword2,
          password,
        },
        headers,
      );
      return ok({});
    } catch (err) {
      if (isValidationError(err.response?.status as number)) {
        return error(<ResponseError>{
          type: 'VALIDATION',
          error: err.response?.data as ValidationError,
        });
      } else {
        return error({
          type: 'UNKNOWN',
          error: new Error(err),
        });
      }
    }
  };

  const getPaymentOptions: IUserRepository['getPaymentOptions'] = async (
    getHeaders: IncomingHttpHeaders,
  ) => {
    try {
      const { data, status } = await userDataSource.getPaymentOptions(
        getHeaders,
      );
      if (status === StatusCode.SuccessNoContent) return ok([]);
      return ok(mapPaymentOptions(data));
    } catch (err) {
      return error(new Error(err));
    }
  };

  const removePaymentOption: IUserRepository['removePaymentOption'] = async (
    method: PAYMENT_METHOD_TYPE,
    headers: IncomingHttpHeaders,
  ) => {
    try {
      const type = mapMethodTypeToType(method);
      if (type) {
        await userDataSource.removePaymentOption(type, headers);
        return ok({ id: 'Method removed successfully' });
      } else throw Error('Invalid method type');
    } catch (err) {
      return error(new Error(err));
    }
  };

  const getNotificationPreferences = async (headers: IncomingHttpHeaders) => {
    try {
      const { data: categories } =
        await userDataSource.getNotificationPreferences(headers);
      return ok(categories);
    } catch (err: any) {
      return error(new Error(err));
    }
  };

  const updateNotificationPreference = async (
    category: string,
    subscriptionStatus: boolean,
    headers: IncomingHttpHeaders,
  ) => {
    try {
      await userDataSource.updateNotificationPreference(
        category,
        subscriptionStatus,
        headers,
      );
      return ok({ message: 'Notification preferences updated successfully' });
    } catch (err: any) {
      return error(new Error(err));
    }
  };

  const updateMyVehicle = async (
    vehicleData: MyVehicleUpdateRequest,
    headers: IncomingHttpHeaders,
  ) => {
    try {
      await userDataSource.updateMyVehicle(vehicleData, headers);
      return ok({ message: 'MyVehicle updated successfully' });
    } catch (err: any) {
      return error(new Error(err));
    }
  };

  return {
    get,
    update,
    updatePhone,
    removeStripeAccount,
    getPaymentOptions,
    removePaymentOption,
    updatePassword,
    getNotificationPreferences,
    updateNotificationPreference,
    updateMyVehicle,
  };
};

export { UserRepository };

const mapPaymentOptions: (paymentOptions: TPaymentCard[]) => PaymentMethod[] = (
  paymentOptions,
) => {
  return paymentOptions.map<PaymentMethod>((option) => {
    return {
      id: option?.type,
      display: mapDisplay(option?.type, option?.display),
      name: option?.display,
      type: mapTypeToMethodType(option?.methodType),
      icon: mapPaymentIcon(option?.type),
      url: '',
      surcharge: '',
      availableNetworks: null,
      selected: false,
    } as PaymentMethod;
  });
};

const mapDisplay = (type?: string, display?: string): string | null => {
  if (display) {
    const firstTwelveDigits = 'xxxxxxxxxxxx';
    switch (type) {
      case 'MASTERCARD':
        return display.replace(firstTwelveDigits, 'Mastercard ');
      case 'AMEX':
        return display.replace(firstTwelveDigits, 'Amex ');
      case 'VISA':
        return display.replace(firstTwelveDigits, 'VISA ');
      default:
        return display;
    }
  } else return null;
};

const mapTypeToMethodType = (
  methodType?: string,
): PAYMENT_METHOD_TYPE | null => {
  switch (methodType) {
    case 'SavedStripeCard':
      return PAYMENT_METHOD_TYPE.SAVED_CARD_STRIPE;
    case 'SavedMobilePhone':
      return PAYMENT_METHOD_TYPE.SAVED_MOBILE;
    case 'SavedPayPalAccount':
      return PAYMENT_METHOD_TYPE.SAVED_PAYPAL;
    default:
      return null;
  }
};

const mapMethodTypeToType = (
  methodType: PAYMENT_METHOD_TYPE,
): string | null => {
  switch (methodType) {
    case PAYMENT_METHOD_TYPE.SAVED_CARD_STRIPE:
      return 'SavedStripeCard';
    case PAYMENT_METHOD_TYPE.SAVED_MOBILE:
      return 'SavedMobilePhone';
    case PAYMENT_METHOD_TYPE.SAVED_PAYPAL:
      return 'SavedPayPalAccount';
    default:
      return null;
  }
};

export const userRepository = UserRepository({
  getUser: accountsApi.getUser,
  updateUser: accountsApi.updateUser,
  updatePhone: accountsApi.updatePhone,
  removeStripeAccount: accountsApi.removeStripeAccount,
  getPaymentOptions: accountsApi.getPaymentOptions,
  removePaymentOption: accountsApi.removePaymentOption,
  updatePassword: accountsApi.updatePassword,
  getNotificationPreferences: accountsApi.getNotificationPreferences,
  updateNotificationPreference: accountsApi.updateNotificationPreference,
  updateMyVehicle: accountsApi.updateMyVehicle,
});
